import { StyledStarIcon } from "components/CustomDataTable/CustomDataTable.style";
import CustomDataTable from "../../../components/CustomDataTable/CustomDataTable";
import ExceptionHandle from "containers/ExceptionHandle/ExceptionHandle";
import pinnedSvg from "assets/caseload/pinned.svg";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import jsPDF from 'jspdf';
import apiHandler from "api";
import endpoint from "api/endpoint";
import moment from "moment";
import unPinnedSvg from "assets/caseload/notPinned.svg";
import { Row, DatePicker, Col, notification } from "antd";
import styled from "styled-components";
import {
  setSelectedEvents,
  setSelectedRowKeys,
  setExceptionErrorMessage,
} from "features/common/commonSlice";
import generatePDF,{ usePDF } from "react-to-pdf";
import Compliance from "assets/compliance.png";
import Nonompliance from "assets/noncompliance.png.png";
import headerPng from "assets/header.png"
import footerPng from "assets/footer.png"
import React from "react";
import './events.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { values } from "lodash";
const { RangePicker } = DatePicker;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const ReadButton = styled.button`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  margin-bottom: 10px;
  color: #000;
  padding: 5px 16px;
  cursor: pointer;
`;
export const DownloadButton = styled.button`
  background: rgb(5, 87, 162);
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
  padding: 5px 16px;
  cursor: pointer;
  float: right;
`;

export const options = {
  bezierCurve: false,
  borderWidth: 1,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      ticks: {
        // Maximum rotation angle for tick labels
        maxRotation: 90,
        // Minimum rotation angle for tick labels
        minRotation: 90
      },
    },
    y: {
      ticks: {
        padding: 7,
        callback: function (value) {
          // Skipping decimal points
          // console.log('======y axis value=======',value)
          return value.toFixed(2);
        },
        stepSize: 0.00001
      },
      // beginAtZero: true
    },

  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    tooltip: {
      callbacks: {
          label: function(context) {
              // console.log('=======context========', context);
              return parseFloat(context.raw).toFixed(7);
          },
      },
    },
    customCanvasBackgroundColor: {
      color: "#ffffff",
    },
  },
};

export const plugins = [
  {
    id: "customCanvasBackgroundColor",
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      ctx.fillStyle = chart.options.plugins.customCanvasBackgroundColor.color;
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  },
];

const Events = () => {
  const { userData, authToken } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const startDate = moment().subtract(12, "months").format("YYYY-MM-DD");
  const [eventData, setEventData] = useState();
  const [deviceNumber, setDeviceNum] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [positiveBrac, setpositiveBrac] = useState(false);
  const [showError, setShowError] = useState(false);
  const { toPDF, targetRef } = usePDF();
  const [startDateAlco, setstartDateAlco] = useState("");
  const [endDateAlco, setendDateAlco] = useState("");
  const [dates, setDates] = useState(null);
  const [peakData, setPeakData] = useState("");
  const [labels, setlabels] = useState([]);
  const [alcoholData, setAlcoholData] = useState([]);
  const [noncomplianceData, setnoncomplianceData] = useState([]);
  const [allnonData, setAllNonData] = useState([]);
  const [objectCounts, setobjectCounts] = useState([]);
  const [alcohalConsumptionCount, setAlcohalConsumptionCount] = useState(0);
  //const labels = ['Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM','Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM', 'Sep 13, 2021 11:28 PM'];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: alcoholData,
        borderColor: "rgb(87, 75, 128)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        lineTension: 0.8,
      },
    ],
  };
  let reportStartDate = null;
  let reportEndDate = null;
  const activeHistoryTab = useSelector(
    (state) => state.common.activeHistoryTab
  );
  const activeParticipantId = useSelector(
    (state) => state.common.activeParticipantId
  );
  const { participantProfileData } = useSelector(
    (state) => state.participantProfileData
  );

  const { selectedEvents } = useSelector((state) => state.common);
  const [display, setDisplay] = useState("none");
  // const showLoader = useSelector((state) => state.showLoader);

  const pinEvent = (eventId, isPinned) => {
    // console.log('pin event', isPinned);
    apiHandler({
      url: endpoint.COMPLIANCE_EVENT,
      method: "POST",
      data: {
        isPinned,
        id: eventId,
      },
      authToken,
    }).then(async (result) => {
      const eventsData = eventData?.map((obj) => {
        if (obj.id === eventId) {
          obj.isPinned = !obj.isPinned;
        }
        return obj;
      });
      setEventData(eventsData);
    });
  };
  
  const setStartDate = (value) => {
    if(value){
      setDates(value);
      value[0] ? reportStartDate = value[0] : reportStartDate = null;
      value[1] ? reportEndDate = value[1] : reportEndDate = null;
      if(reportStartDate && reportEndDate) {
        getReportData(reportStartDate, reportEndDate, true);
      }
    } else {
      setDates(value);
      reportStartDate = null;
      reportEndDate = null;
    }
   
  }

  const getEvents = async () => {
    apiHandler({
      url:
        endpoint.ENROLL_PARTICIPANT +
        "/" +
        activeParticipantId +
        "/events/" +
        startDate,
      authToken,
    }).then(async (result) => {
      console.log("enroll participant evnts : ", result.data);
      if (!result) return;
      // getting the last seven days event.
      let newEvents = await result?.data?.map((obj) => {
        if (!obj.isRead) {
          return obj;
        } else {
          return "";
        }
      });

      // removing empty strings and only storing usable objects.
      newEvents = newEvents?.filter((obj) => {
        if (obj) return obj;
      });

      let data = [];
      //preparing the data objects for the table.
      data = newEvents?.map((obj, index) => {
        if(userData?.websiteUserType?.isSuperUser){
        return {
          key: index,
          id: obj.id,
          eventType: obj.eventType.name,
          device: obj?.device?.serialNumber,
          eventStatus: obj?.eventManualResolutionStatusName == "Not an"?'Not an alcohol consumption':obj?.eventManualResolutionStatusName,
          isPinned: (
            <StyledStarIcon
              onClick={() => {
                pinEvent(obj.id, !obj.isPinned);
              }}
              key={index}
              src={obj.isPinned ? pinnedSvg : unPinnedSvg}
            ></StyledStarIcon>
          ),
          scheduled: (
            <>
              <p>{moment(obj.createdAt).format("MMM DD, YYYY hh:mm A")}</p>
              {/* <p>{moment(obj.receivedAt).format("MMM D, YYYY h:mm A z")}</p> */}
            </>
          ),
          compliant: (
            <>
              <p>
                {obj.compliantAt &&
                  moment(obj.compliantAt).format("MMM DD, YYYY hh:mm A")}
              </p>
            </>
          ),
          notificationTime: (
            <>
              <p>
                {obj.notifiedSubscribersCreatedAt &&
                  moment(obj.notifiedSubscribersCreatedAt).format("MMM DD, YYYY hh:mm A")}
              </p>
            </>
          ),
          updatedAt: (
            <>
              <p>{moment(obj.updatedAt).format("MMM DD, YYYY hh:mm A")}</p>
            </>
          ),
          status: obj.eventStatus,
        };
      }else{
        if(obj?.eventType?.name !=="Watch Sensor Fault"&&obj?.eventType?.name !=="Obstruction Tamper"&&
          obj?.eventType?.name !=="Strap Tamper"&&obj?.eventType?.name !=="Case Tamper"&&
          obj?.eventType?.name !=="Critical Battery"&&obj?.eventType?.name !=="Critically Low Breathalyzer Battery"&&
          obj?.eventType?.name !=="Critically Low Watch Battery"&&obj?.eventType?.name !=="Critically Low Phone Battery"&&
          obj?.eventType?.name !=="Critically Low GPS Battery"
        ) {
          return {
            key: index,
            id: obj.id,
            eventType: obj.eventType.name,
            device: obj?.device?.serialNumber,
            eventStatus: obj?.eventManualResolutionStatusName == "Not an"?'Not an alcohol consumption':obj?.eventManualResolutionStatusName,
            isPinned: (
              <StyledStarIcon
                onClick={() => {
                  pinEvent(obj.id, !obj.isPinned);
                }}
                key={index}
                src={obj.isPinned ? pinnedSvg : unPinnedSvg}
              ></StyledStarIcon>
            ),
            scheduled: (
              <>
                <p>{moment(obj.createdAt).format("MMM DD, YYYY hh:mm A")}</p>
                {/* <p>{moment(obj.receivedAt).format("MMM D, YYYY h:mm A z")}</p> */}
              </>
            ),
            compliant: (
              <>
                <p>
                  {obj.compliantAt &&
                    moment(obj.compliantAt).format("MMM DD, YYYY hh:mm A")}
                </p>
              </>
            ),
            notificationTime: (
              <>
                <p>
                  {obj.notifiedSubscribersCreatedAt &&
                    moment(obj.notifiedSubscribersCreatedAt).format("MMM DD, YYYY hh:mm A")}
                </p>
              </>
            ),
            updatedAt: (
              <>
                <p>{moment(obj.updatedAt).format("MMM DD, YYYY hh:mm A")}</p>
              </>
            ),
            status: obj.eventStatus,
          };
        }
      }
      });
      data = data.filter(d => d&&d!==undefined);
      console.log('======evnt======',data)
      setEventsList(data);
    });
    dispatch(setSelectedEvents());
  };

  const columns = [
    {
      title: "Pin",
      dataIndex: "isPinned",
      width: "50px"
     
    },
    {
      title: "Device",
      dataIndex: "device",
      width: "100px"
     
    },
    {
      title: "Event Type",
      dataIndex: "eventType",
       width: "150px"

    },
    {
      title: "Event Status",
      dataIndex: "eventStatus",
        width: "110px"
    
    },
    
    {
      title: "Status",
      dataIndex: "status",
     width: "100px"
    },
    {
      title: "Created At",
      dataIndex: "scheduled",
       width: "170px"

    },
    // {
    //   title: "Updated At",
    //   dataIndex: "updatedAt",
    // },
    {
      title: "Compliant At",
      dataIndex: "compliant",
       width: "170px"
      // sorter: (a, b) => { return new Date(moment(a.compliantDate, "MMMM Do YYYY, h:mm:ss a").format("LLL")) -
      // new Date(moment(b.compliantDate, "MMMM Do YYYY, h:mm:ss a").format("LLL"))},
    },
    {
      title: "Notification Sent At",
      dataIndex: "notificationTime",
       width: "160px"
      // sorter: (a, b) => { return new Date(moment(a.notificationDate, "MMMM Do YYYY, h:mm:ss a").format("LLL")) -
      // new Date(moment(b.notificationDate, "MMMM Do YYYY, h:mm:ss a").format("LLL"))},
    }
  ];

  const downloadReport = async () => {
    notification.info({
      description: "Report Download is In Progress",
      placement: "topRight",
      duration: 5,
    });
    setDisplay("block");
    setTimeout(() => {
      const container = document.getElementById("container");
      const doc = new jsPDF();
      doc.html(container, {
        callback: function (doc) {
          const filename = `${participantProfileData?.fullName}_${positiveBrac ? 'non_compliance' : 'compliance'}.pdf`;
          doc.save(filename);
          setTimeout(() => {
            setDisplay("none");
          }, 500);
        },
        html2canvas: {
          scale: 0.235,
          useCORS: true,
        },
        margin: [0, 0, 0, 0],
      });
    }, 3000);
  };
  const markAsRead = async () => {
    let localitem = JSON.parse(localStorage.getItem('selectedItem'));
    try {
      if(localitem.length>0){
      let eventsData = [];
      eventsData = localitem?.map((item) => {
        return {
          id: item,
          isRead: true,
        };
      });

      await apiHandler({
        url: endpoint.COMPLIANCE_EVENTS,
        method: "POST",
        data: eventsData,
        authToken,
      }).then(async (result) => {
        let selected = [];
        dispatch(setSelectedRowKeys(selected));
        localStorage.removeItem('selectedItem')
         dispatch(setSelectedEvents([]))
        getEvents();
        notification.info({
          description: result.data.message,
          placement: "topRight",
          duration: 5,
        });
      });
    }
    } catch (error) {
      dispatch(
        setExceptionErrorMessage("Select An Event To Complete Mark as read")
      );
      setShowError(true);
      console.log("error here", error);
    }
  };

  useEffect(() => {
    if (!activeHistoryTab) {
      getEvents();
    }
  }, [activeHistoryTab]);

  const getAllCompliance = () => {};

  useEffect(() => {
    getReportData(participantProfileData.latestProgram?.activationDate, new Date(), false);
    // participantProfileData.openComplianceEvents.forEach(element => {
    // apiHandler({
    //   url: endpoint.ENROLL_PARTICIPANT+"/"+participantProfileData?.id+"/events/" +moment(element.receivedAt).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""),
    //   authToken,
    // }).then((result) => {
    //   console.log("all events here",result);
    // })
    // if(element.eventType.deviceTypeName =="Breathalyzer"){
    //setpositiveBrac(true);
    // console.log(element);

    //  if(element.eventType.indicator =="Behavior" && element.eventType.isMaintenance == false){

    //  setnoncomplianceData(noncompliance);
    // apiHandler({
    //   url: endpoint.ENROLL_PARTICIPANT+"/"+participantProfileData?.id+"/watchMetrics/" +moment(element.receivedAt).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":",""),
    //   authToken,
    // }).then((result) => {
    //   let alcData = [];
    //   let lblData = [];
    //   if (result&&result.data) {
    //    result.data.forEach(el=>{
    //     if(el.alcoholConcentration!==null){
    //       alcData.push(el.alcoholConcentration);
    //       lblData.push(el.evaluatedAt);
    //     }
    //    });
    //    setstartDateAlco(lblData[0]);
    //    setendDateAlco(lblData[lblData.length-1]);
    //    setPeakData(Math.max.apply(null, alcData));
    //    setlabels(lblData);
    //    setAlcoholData(alcData);
    //   }
    // });

    //  });
    dispatch(setSelectedEvents());
  }, []);

  const getReportData = (startDate, endDate, violation) => {
    let query = ''
    // const tempEndDate = moment(endDate);
    // tempEndDate.set({hour:23,minute:59,second:59,millisecond:0})
    if(violation) {
      query = moment(startDate).format(
        "YYYY-MM-DD"
      )+'/'+moment(endDate).format(
        "YYYY-MM-DD"
      )+'/violations/Watch'
    } else {
      query = moment(startDate).format(
        "YYYY-MM-DD"
      )+'/'+moment(endDate).format(
        "YYYY-MM-DD"
      )
      // query = moment(startDate).format(
      //   "YYYY-MM-DD"
      // )+'/'+moment(tempEndDate).format("YYYY-MM-DDTHH:mm")
    }
    if (participantProfileData.latestProgram) {
      apiHandler({
        url:
          endpoint.ENROLL_PARTICIPANT +
          "/" +
          participantProfileData?.id +
          "/events/" + query,
        authToken,
      }).then(async (result) => {
        console.log("events data qq", result.data);
        const allnon = [];
        if (Array.isArray(result.data)) {
          // setDeviceNum(result?.data[0]?.device?.serialNumber);

         
          let objectCt = [];
          let resolvedAlchohalCnt = 0;
          setAllNonData([]);
          result.data.map((element) => {
            if(element?.device?.deviceTypeName === "Watch"){
              
              if(element.eventType.name !== "Alcohol Consumption" && userData?.websiteUserType?.isSuperUser){
                objectCt.push( element?.eventType?.name)
              }
              
            }
            const noncompliance = [];
            if (
              element.eventType.indicator == "Behavior" &&
              element.eventType.isMaintenance == false &&
              element.eventType.name === "Alcohol Consumption"  &&
              element.isManuallyResolved === true
            ) {
              setpositiveBrac(true);
              resolvedAlchohalCnt++;
              // if(element?.assignedDevices){
              //   element?.assignedDevices.map(el=>{
              //     if(el?.device?.deviceTypeName =="Watch"){
                    
              //     }
              //   })
              // }
              setDeviceNum(result.data[result.data.length-1].device.serialNumber);
              noncompliance.push(element);
              let url =
                endpoint.ENROLL_PARTICIPANT +
                "/" +
                participantProfileData?.id +
                "/alcEventMetrics/" +
                moment(element.createdAt).format("YYYY-MM-DDTHH:mm") +
                moment().format("Z").replaceAll(":", "");
              if (element.compliantAt !== null) {
                // const tempComplientAt = moment(element.compliantAt);
                // tempComplientAt.set({hour:23,minute:59,second:59,millisecond:0})
                url =
                  endpoint.ENROLL_PARTICIPANT +
                  "/" +
                  participantProfileData?.id +
                  "/alcEventMetrics/" +
                  moment(element.createdAt).format("YYYY-MM-DDTHH:mm") +
                  moment().format("Z").replaceAll(":", "") +
                  "/" +
                  moment(element.compliantAt).format("YYYY-MM-DDTHH:mm") +
                  // tempComplientAt.format("YYYY-MM-DDTHH:mm") +
                  moment().format("Z").replaceAll(":", "");
              }

              console.log("events data ss", url);
              apiHandler({
                url: url,
                authToken,
              }).then(async (results) => {
                let alcData = [];
                let lblData = [];
                if (results && results.data) {
                  console.log("allresultddd", results.data.alcoholConcentrations.map(el=> parseFloat(Number(el*0.21).toFixed(7))));
                  const dumData = {
                    alcData: results.data.alcoholConcentrations,
                    lblData: results.data.timestamps.map((el) => {
                      return moment(el).format("MM/DD/YYYY hh:mm A");
                    }),
                    startDate: moment(results.data.timestamps[0]).format(
                      "MMM DD, YYYY hh:mm A"
                    ),
                    endDate: moment(
                      results.data.timestamps[
                        results.data.timestamps.length - 1
                      ]
                    ).format("MMM DD, YYYY hh:mm A"),
                    peakData:
                      element.alcoholConcentration !== null
                        ? element.alcoholConcentration
                        : 0,
                    serialNumber: element.device.serialNumber,
                    chartData: {
                      labels: results.data.timestamps.map((el) => {
                        return moment(el).format("MMM DD, YYYY hh:mm A");
                        // return moment(el).format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","");
              
                      }),
                      datasets: [
                        {
                          label: "Dataset 1",
                          data: results.data.alcoholConcentrations.map(el=> parseFloat(Number(el*0.21).toFixed(7))),
                          borderColor: "rgb(87, 75, 128)",
                          backgroundColor: "rgba(255, 99, 132, 0.5)",
                          lineTension: 0,
                        },
                      ],
                    },
                    showChart: true,
                    showDescription: false,
                    eventType: element.eventType.name, 
                  };

                  console.log("dumm data : ", dumData);

                  //allnon.push(dumData);
                  setAllNonData((oldValue) => [...oldValue, dumData]);
                  console.log("alll non lll", allnonData);
                  //setstartDateAlco(lblData[0]);
                  //setendDateAlco(lblData[lblData.length-1]);
                  //setPeakData(Math.max.apply(null, alcData));
                  //setlabels(lblData);
                  //setAlcoholData(alcData);
                }
              });
            } /* else if ((element.eventType.name === "Obstruction Tamper" ||
                element.eventType.name === "Strap Tamper" ||
                element.eventType.name === "Case Tamper" ||
                element.eventType.name === "Critically Low Battery" ||
                element.eventType.name === "Critically Low Watch Battery") &&
                userData?.websiteUserType?.isSuperUser
            ) {
              
              // setDeviceNum(result.data[result.data.length-1].device.serialNumber);
              noncompliance.push(element);
              

              // console.log("events data ss", url);
              const dumData = {
                eventType: element.eventType.name, 
                alcData: [],
                lblData: [],
                startDate: element.createdAt ? moment(element.createdAt).format(
                  "MMM DD, YYYY hh:mm A"
                ) : 'N/A',
                endDate: element.compliantAt ? moment(element.compliantAt).format("MMM DD, YYYY hh:mm A") : 'N/A',
                serialNumber: element.device.serialNumber,
                chartData: {},
                showChart: false,
                peakData: null,
                showDescription: true
              };

              // console.log("dumm data : ", dumData);

              //allnon.push(dumData);
              setAllNonData((oldValue) => [...oldValue, dumData]);
              // console.log("alll non lll", allnonData);
            } */
          });
          setAlcohalConsumptionCount(resolvedAlchohalCnt);
          // setobjectCounts(generateCount(objectCt));
        }
        // setAllNonData(allnon);
        console.log("alll non", allnonData);
      });
    }
  };
  function generateCount(array_elements) {
    //array_elements = ["a", "b", "c", "d", "e", "a", "b", "c", "f", "g", "h", "h", "h", "e", "a"];

    // array_elements.sort();

    // var current = null;
    // var cnt = 0;
    // let str = "";
    // for (var i = 0; i < array_elements.length; i++) {
    //     if (array_elements[i] != current) {
    //         if (cnt > 0) {
    //             str = str+ cnt+ " "+current +", ";
    //         }
    //         current = array_elements[i];
    //         cnt = 1;
    //     } else {
    //         cnt++;
    //     }
    // }
    // if (cnt > 0) {
    //   str = str+ cnt+ " "+current;
    // }
    // return str;
}
  return (
    <>
      <style>{`
    table th,
    td {
        padding: 0px !important;
    }
  `}</style>
      <h2>New Events</h2>
      <Row style={{ justifyContent: "space-between" }}>
        <Col>
          <ReadButton onClick={() => markAsRead()}>Mark as Read</ReadButton>
        </Col>
        <Col>
          <RangePicker
          value={dates}
          showTime={false}
          format="MMM DD, YYYY"
          showSecond={false}
          minuteStep={30}
          onCalendarChange={(v) => setStartDate(v)}
          style={{ width: 230, float: "right" }}
          /> 
        </Col>
        <Col>
          {positiveBrac ? (
            <DownloadButton onClick={() => downloadReport()}>
              Download Non Compliance Report
            </DownloadButton>
          ) : (
            <>
              <DownloadButton onClick={() => downloadReport()}>
                Download Compliance Report
              </DownloadButton>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <p style={{ color: "red", fontWeight: 600 }}>
          Mark as Read removes events from this page but they will be viewable
          in the View History page.
        </p>
      </Row>
      <CustomDataTable
        showSelect={true}
        position={["bottomLeft"]}
        columns={columns}
        data={eventsList}
        tableSource="events"
        // showPagination={true}
      />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
<br />
      <div
      id="container"
        ref={targetRef}
        style={{
        display,
          //padding: " 0 50px 0 50px",
          width:"900px",
          // marginLeft:"-251px"
        }}
      >
        {/* <div ref={targetRef}> */}
        <img width="100%" src={headerPng} />
        <div style={{padding: " 0 50px 0 50px" }}>
          <div style={{ height:"auto", maxWidth: "780px",marginBottom:"12px"}}>
            {" "}
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <span></span>
            </p>
            {/* this tables adds a compliance or non compliance image only based on positiveBrc state. */}
            <table border={0} cellSpacing={0} cellPadding={0}>
              <tbody>
                <tr>
                  <td>
                    {positiveBrac ? (
                      <>
                      
                      <h1 style={{textAlign:"center"}}>TRAC Watch</h1>
                      <h1 style={{textAlign:"center"}}>Non-Compliance Report</h1>
                      </>
                    ) : (
                      // <img width="100%" src={Compliance} />
                      <h1 style={{textAlign:"center"}}>Compliance Report</h1>
                    )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p />
            {/* this tables renders the basic details */}
            <table
              style={{
                display: "block",
                borderCollapse: "collapse",
                marginLeft: "6pt",
                paddingBottom: "3px",
                paddingTop: "17px",
                borderBottom: "1px solid gray",
                borderTop: "1px solid gray",
                
              }}
              cellSpacing={0}
            >
              <tbody>
                <tr>
                  <td>Client:</td>
                  <td style={{ fontWeight: "600",width:"260px" }}>
                  &nbsp;&nbsp;&nbsp;{participantProfileData?.fullName}
                  </td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>Supervising Authority:</td>
                  <td style={{width:"120px"}}>&nbsp; &nbsp; <b>{participantProfileData?.agency?.name}</b></td>
                </tr>
                <tr>
                  <td>Case Number:</td>
                  <td>&nbsp;&nbsp;&nbsp;<b>{participantProfileData?.caseNumber?participantProfileData?.caseNumber:'N/A'}</b></td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>Monitored by:</td>
                  <td> &nbsp; &nbsp; <b>CAM Systems</b></td>
                </tr>
                <tr>
                  <td>Offense:</td>
                  <td style={{width:"187px"}}>&nbsp;&nbsp;&nbsp;<b>{participantProfileData?.offense}</b></td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  {/* <td>Report Prepared on:</td>
                  <td style={{width:"187px"}}>
                    {" "}
                    &nbsp; &nbsp; <b>{moment(new Date()).format("MMM DD, YYYY")}</b>
                  </td> */}
                  <td>Program Enrollment Date:</td>
                  <td> &nbsp; &nbsp;&nbsp;
                    <b>{moment(participantProfileData?.latestProgram?.activationDate).format(
                      "MMM DD, YYYY"
                    ) === "Invalid date"
                      ? ""
                      : moment(participantProfileData?.latestProgram?.activationDate).format(
                          "MMM DD, YYYY"
                        )}</b>
                  
                  </td>
                </tr>
                <tr>
                  <td>Date Of Birth:</td>
                  <td>&nbsp;&nbsp;&nbsp;
                    <b>{moment(participantProfileData?.birthDate).format(
                      "MMM DD, YYYY"
                    ) === "Invalid date"
                      ? ""
                      : moment(participantProfileData?.birthDate).format(
                          "MMM DD, YYYY"
                        )}</b>
                  </td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>TRAC Watch Number:</td>
                  <td>&nbsp; &nbsp; <b>{deviceNumber}</b></td>
                  {/* <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td> */}
                  {/* <td>Assigned Equipment:</td>
                  <td style={{width:"187px"}}>
                    &nbsp; &nbsp;
                    {
                      participantProfileData?.latestProgram?.alcoholProgramType
                        ?.name
                    }
                  </td> */}
                </tr>
                <tr>
                  <td>Address:</td>
                  <td style={{width:"187px"}}>
                    &nbsp;&nbsp;<b>{participantProfileData?.address?.address123}{" "}
                    {participantProfileData?.address?.cityStateZip}</b>
                  </td>
                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                  <td>Days Monitored:</td>
                  <td>
                    &nbsp; &nbsp;&nbsp;
                    <b>{moment(new Date()).diff(
                      moment(
                        participantProfileData?.latestProgram?.activationDate
                      ),
                      "days"
                    )+1}{" "}
                    Days</b>
                  </td>
                </tr>
                <tr>
                  <td> &nbsp; &nbsp;</td>
                </tr>
                {/* <tr>
                  <td>Program Enrollment Date:</td>
                  <td> &nbsp; &nbsp;
                    <b>{moment(participantProfileData?.latestProgram?.activationDate).format(
                      "MMM DD, YYYY"
                    ) === "Invalid date"
                      ? ""
                      : moment(participantProfileData?.latestProgram?.activationDate).format(
                          "MMM DD, YYYY"
                        )}</b>
                  
                  </td>
                </tr> */}
              </tbody>
            </table>
            {positiveBrac ? (
              ""
            ) : (
              <>
                <p
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "7pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    textAlign: "left",
                  }}
                >
                  The following is a business record prepared on the date first
                  written above and includes all monitoring for the{" "}
                  <b>
                    {moment(new Date()).diff(
                      moment(
                        participantProfileData?.latestProgram?.activationDate
                      ),
                      "days"
                    )+1}{" "}
                    Days{" "}
                  </b>
                  from the{" "}
                  <b>
                    {moment(
                      participantProfileData?.latestProgram?.activationDate
                    ).format("MMM DD, YYYY")}{" "}
                  </b>
                  through the Report Prepared on{" "}
                  <span className="p">
                    date:{moment(new Date()).format("MMM DD, YYYY")}
                  </span>
                </p>
                {/* Activity */}
                <h1
                  style={{
                    paddingLeft: "7pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Activity:
                </h1>
                <p
                  style={{
                    paddingTop: "0",
                    paddingLeft: "7pt",
                    textIndent: "0pt",
                    lineHeight: "114%",
                    textAlign: "left",
                  }}
                >
                  {participantProfileData?.fullName} was placed on the TRAC
                  Watch program on{" "}
                  {moment(
                    participantProfileData?.latestProgram?.activationDate
                  ).format("MMM DD, YYYY")}
                  , and continues to be monitored under the TRAC Watch program
                  as of the date of this report.
                  There have been no alcohol or tamper events during the monitoring period.
                </p>
              </>
            )}
          </div>
          </div>

          {/* if postiveBrac is true it will renders all the events passing the condtion indicator=='behaviour' && isMaintenec == false && eventType.name == 'Alcohol Consumption' && isManuallyResolved == true*/}
          {positiveBrac ? (
            <>
              {allnonData.map((object, i) => {
                if (i == 0) {
                  return (
                    <>
                      <div
                        style={{
                          height: "809px",
                          padding: " 0 50px 0 50px",
                          aspectRatio: "auto",
                          // position: "relative",
                          // top: positiveBrac ?  "-301px" : "-285px",
                        }}
                      >
                        <p
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >
                          This is a business record created on or about the date
                          of the occurrence and in the normal course of
                          business.
                        </p>
                        <p
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >
                         The TRAC Watch has detected <b>{alcohalConsumptionCount} Confirmed Alcohol{/* , {userData?.websiteUserType?.isSuperUser ?objectCounts:''}  */}
                          </b> event(s) by the Participant during the term of this Program.
                        </p>
                        <p
                          style={{
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >
                          The TRAC Watch has detected the following <b>{object.eventType}</b>. Event details below.
                        </p>
                        <p
                          style={{
                            paddingTop: "0",
                            paddingLeft: "7pt",
                            textIndent: "0pt",
                            lineHeight: "114%",
                            textAlign: "left",
                          }}
                        >

                        {object.eventType === 'Alcohol Consumption'&&(
                          <>
                            <span
                              style={{
                                fontWeight: "bolder",
                                display: "inline-block",
                                margin: "2px 0px 2px 0px",
                              }}
                            >
                              Confirmed Alcohol Consumption{" "}
                            </span>
                            <br />
                          </>
                          
                        )}
                          
                          <Row>
                            <Col md={12}>Event Start Time: <b>{object.startDate}</b></Col>
                            <Col md={12}>Event End Time: <b>{object.endDate}</b></Col>
                          </Row>
                      
                          
                          
                          <br />
                          {object.peakData&&(
                            <>
                              Peak Alcohol Content: <b>{object.peakData} mg/L/BrAC: {Number(object.peakData*0.21).toFixed(2)}</b> <br />
                            </>
                          )}
                          
                         
                          {/* {moment(object.endDate).format("YYYY-MM-DD HH:mm:ss")} */}
                          
                          {object.showChart&&(
                            <div class="grid-container">
                              <div class="item1"><span style={ {marginTop: "130px",position: "absolute",transform: "rotate(-90deg)",writingMode:"vertical-lr"}}>BrAC</span></div>
                              <div class="item2"><Line options={options} data={object.chartData} plugins={plugins} /></div>
                            </div>)}

                          {object.showDescription&&(
                            <>
                              <Row>
                                <Col span={24} style={{
                                  marginBottom: "18px",
                                  }}>
                                  <b>Event Description:
                                  </b>
                                </Col>
                              </Row>
                              {object.eventType === 'Obstruction Tamper'&&(
                                <>
                                  <label>
                                    An Obstruction Tamper event indicates interference with the TRAC Watch's operation. The TRAC Watch relies
                                    on various sensors to ensure proper function. When a sensor detects an anomaly indicating something placed
                                    between the TRAC Watch and the Participant’s skin such as:
                                  </label>
                                  
                                  <ul  style={{
                                  margin: "18px 0px",
                                  }}>
                                    <li><b>Foreign objects: </b> Items like paper, fabric, or other materials.</li>
                                    <li><b>Substances: </b> Liquids, gels, or powders.</li>
                                  </ul>

                                  <label>
                                    These actions disrupt the sensor readings and trigger an Obstruction Tamper event, compromising the TRAC
                                    Watch's ability to monitor and report data. The Participant has signed an Agreement stating they will not tamper
                                    with the TRAC Watch. The occurrence of an Obstruction Tamper event indicates a disregard for program rules
                                    and is a violation of the Agreement and a violation of the Court Order.
                                  </label>

                                </>
                              )}
                              {object.eventType === 'Strap Tamper'&&(
                                <>
                                  <label>
                                    A Strap Tamper event indicates interference with the TRAC Watch's operation. The TRAC Watch relies on
                                    various sensors to ensure proper function. When a sensor detects an anomaly indicating the strap's integrity is
                                    compromised, a Strap Tamper event is triggered. This could be due to actions like:
                                  </label>
                                  
                                  <ul   style={{
                                  margin: "18px 0px",
                                  }}>
                                    <li><b>Excessive force: </b> Pulling or yanking the strap with extreme force.</li>
                                    <li><b>Damage: </b> Cutting or attempting to sever the strap.</li>
                                    <li><b>Buckle manipulation: </b> Losing or detaching the buckle.</li>
                                    <li><b>Strap removal: </b>  Completely removing the strap from the watch.</li>
                                  </ul>

                                  <label>
                                    These actions disrupt the sensor readings and trigger a Strap Tamper event, compromising the TRAC Watch's
                                    ability to monitor and report data. The Participant has signed an Agreement stating they will not tamper with the
                                    TRAC Watch. The occurrence of a Strap Tamper event indicates a disregard for program rules and is a violation
                                    of the Agreement and a violation of the Court Order.
                                  </label>
                                  

                                </>
                              )}
                              {object.eventType === 'Case Tamper'&&(
                                <>
                                  
                                  <label>
                                    A Case Tamper event indicates interference with the TRAC Watch's operation. The TRAC Watch relies on
                                    various sensors to ensure proper function. When a sensor detects an anomaly indicating the case's integrity is
                                    compromised, a Case Tamper event is triggered. This can happen if the TRAC Watch is:

                                  </label>
                                  
                                  <ul   style={{
                                  margin: "18px 0px",
                                  }}>
                                    <li><b>Opened: </b> Any attempt to pry open or disassemble the watch casing.</li>
                                    <li><b>Destroyed: </b> Actions that damage or break the watch casing.</li>
                                    <li><b>Submerged: </b> Exposing the watch to water or other liquids beyond its specified water resistance.</li>
                                  </ul>

                                  <label>
                                    These actions disrupt the sensor readings and trigger a Case Tamper event, compromising the TRAC Watch's
                                    ability to monitor and report data. The Participant has signed an Agreement stating they will not tamper with the
                                    TRAC Watch. The occurrence of a Case Tamper event indicates a disregard for program rules and is a violation
                                    of the Agreement and a violation of the Court Order.

                                  </label>
                                  
                                </>
                              )}
                              {(object.eventType === 'Critically Low Battery' ||
                                object.eventType === 'Critically Low Watch Battery'
                              )&&(
                                <>
                                  <label>
                                    A Critically Low Watch Battery event indicates a significant lapse in maintaining the TRAC Watch. Participants
                                    are required to charge the device at least every 30 days and acknowledge this responsibility through a signed
                                    agreement. This ensures the TRAC Watch, which has a 90-day battery life, remains operational.
                                    <br />
                                    Despite this requirement, and to further prevent this situation, Participants are provided with multiple warnings:
                                  </label>
                                  
                                  <ul   style={{
                                  margin: "18px 0px",
                                  }}>
                                    <li><b>Continuous Battery Monitoring: </b> The TRAC Monitor app displays the watch's battery percentage at all
                                    times, allowing for ongoing awareness of the charge level.</li>
                                    <li><b>Persistent Low Battery Alerts: </b> When the battery reaches 10%, the watch vibrates for one second at
                                    intervals, serving as a constant reminder to charge the device.</li>
                                  </ul>

                                  <label>
                                    The occurrence of a Critically Low Watch Battery event indicates a disregard for program rules and is a violation
                                    of the agreement and the Court Order.
                                  </label>
                                </>
                              )}
                            </>
                          )}
                          {/* <Row>
                          <Col span={2}></Col>
                          <Col span={22}></Col>
                        </Row> */}
                        </p>
                      </div>
                      <img width="100%" src={footerPng} />
                    </>
                  );
                } else {
                  <div style={{pageBreakBefore: "always"}}>&nbsp;</div> 
                  return (
                    <>
                        <img width="100%" src={headerPng} />
                        <div style={{height: i + 1 === allnonData.length ? "1125px" : "1126px",}}>
                        <div style={{padding: " 0 50px 0 50px" }}>
                          <div style={{ height:"auto", maxWidth: "780px"}}>
                            <table border={0} cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td>
                                    
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                            <br />
                            <table
                            
                              style={{
                                display: "block",
                                borderCollapse: "collapse",
                                marginLeft: "6pt",
                                paddingBottom: "10px",
                                paddingTop: "10px",
                                borderBottom: "1px solid gray",
                                borderTop: "1px solid gray",
                              }}
                              cellSpacing={0}
                            >
                              <tbody>
                                <tr>
                                  <td>Client:</td>
                                  <td style={{ fontWeight: "600" ,width:"260px"}}>
                                  &nbsp;&nbsp;&nbsp;{participantProfileData?.fullName}
                                  </td>
                                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                  <td>Supervising Authority:</td>
                                  <td style={{width:"187px"}}>
                                    &nbsp; &nbsp;{" "}
                                    <b>{participantProfileData?.agency?.name}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Case Number:</td>
                                  <td>&nbsp;&nbsp;&nbsp;<b>{participantProfileData?.caseNumber ? participantProfileData?.caseNumber : 'N/A'}</b></td>
                                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                  <td>Monitored by:</td>
                                  <td> &nbsp; &nbsp; <b>CAM Systems</b></td>
                                </tr>
                                <tr>
                                  <td>Offense:</td>
                                  <td style={{width:"187px"}}>&nbsp;&nbsp;&nbsp;<b>{participantProfileData?.offense}</b></td>
                                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                  {/* <td>Report Prepared on:</td>
                                  <td>
                                    &nbsp; &nbsp;{" "}
                                    <b>{moment(new Date()).format("MMM DD, YYYY")}</b>
                                  </td> */}
                                  <td>Program Enrollment Date:</td>
                                  <td> &nbsp; &nbsp;&nbsp;
                                    <b>{moment(
                                      participantProfileData?.latestProgram
                                        ?.activationDate
                                    ).format("MMM DD, YYYY")}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Date Of Birth:</td>
                                  <td>&nbsp;&nbsp;&nbsp;
                                  <b>{moment(participantProfileData?.birthDate).format(
                                    "MMM DD, YYYY"
                                  ) === "Invalid date"
                                    ? ""
                                    : moment(participantProfileData?.birthDate).format(
                                        "MMM DD, YYYY"
                                      )}</b>
                                  </td>
                                  {/* <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                  <td>Assigned Equipment:</td>
                                  <td style={{width:"187px"}}>
                                    &nbsp; &nbsp;
                                    {
                                      participantProfileData?.latestProgram
                                        ?.alcoholProgramType?.name
                                    }
                                  </td> */}
                                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                  <td>TRAC Watch Number:</td>
                                  <td>&nbsp; &nbsp; <b>{deviceNumber}</b></td>
                                </tr>
                                <tr>
                                  <td>Address:</td>
                                  <td style={{width:"187px"}}>
                                  &nbsp;&nbsp;<b>{participantProfileData?.address?.address123}{" "}
                                  {participantProfileData?.address?.cityStateZip}</b>
                                  </td>
                                  <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                  <td>Days Monitored:</td>
                                  <td>
                                    &nbsp; &nbsp;&nbsp;
                                    <b>{moment(new Date()).diff(
                                      moment(
                                        participantProfileData?.latestProgram
                                          ?.activationDate
                                      ),
                                      "days"
                                    )+1}{" "}
                                    Days</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td> &nbsp; &nbsp;</td>
                                  {/* <td>Program Enrollment Date:</td>
                                  <td> &nbsp; &nbsp;
                                    <b>{moment(
                                      participantProfileData?.latestProgram
                                        ?.activationDate
                                    ).format("MMM DD, YYYY")}</b>
                                  </td> */}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                        style={{
                          padding: " 0 50px 0 50px",
                          aspectRatio: "auto",
                        }}
                        >
                          <p
                              style={{
                                paddingLeft: "7pt",
                                textIndent: "0pt",
                                lineHeight: "114%",
                                textAlign: "left",
                                marginTop:"14px"
                              }}
                            >
                              This is a business record created on or about the date
                              of the occurrence and in the normal course of
                              business.
                            </p>
                            <p
                              style={{
                                paddingLeft: "7pt",
                                textIndent: "0pt",
                                lineHeight: "114%",
                                textAlign: "left",
                              }}
                            >
                            The TRAC Watch has detected <b>{alcohalConsumptionCount} Confirmed Alcohol {/* {userData?.websiteUserType?.isSuperUser ?objectCounts:''}  */}
                              </b> event(s) by the Participant during the term of this Program.
                            </p>
                            <p
                              style={{
                                paddingLeft: "7pt",
                                textIndent: "0pt",
                                lineHeight: "114%",
                                textAlign: "left",
                              }}
                            >
                            The TRAC Watch has detected the following <b>{object.eventType}</b>. Event details below.

                            </p>
                          <p
                            style={{
                              paddingTop: "0",
                              paddingLeft: "7pt",
                              textIndent: "0pt",
                              lineHeight: "114%",
                              textAlign: "left",
                            }}
                          >
                            {object.eventType === 'Alcohol Consumption'&&(
                              <>
                                <span
                                  style={{
                                    fontWeight: "bolder",
                                    display: "inline-block",
                                    margin: "10px 0px 2px 0px",
                                  }}
                                >
                                  Confirmed Alcohol Consumption{" "}
                                </span>
                                <br />
                              </>
                            
                            )}
                            
                            <Row>
                                <Col md={12}>Event Start Time: <b>{object.startDate}</b></Col>
                                <Col md={12}>Event End Time: <b>{object.endDate}</b></Col>
                              </Row>
                            <br />
                            <br />
                            {object.peakData&&(
                                <>
                                  Peak Alcohol Content: <b>{object.peakData} mg/L/BrAC: {Number(object.peakData*0.21).toFixed(2)}</b> <br />
                                </>
                            )}
                          
                        
                          {object.showChart&&(
                              <div class="grid-container">
                                  <div class="item1"><span style={ {marginTop: "130px",position: "absolute",transform: "rotate(-90deg)",writingMode:"vertical-lr"}}>BrAC</span></div>
                                  <div class="item2"><Line options={options} data={object.chartData} plugins={plugins} /></div>
                              </div>
                            )}

                            {object.showDescription&&(
                                <>
                                  <Row>
                                    <Col span={24} style={{
                                      marginBottom: "18px",
                                      }}>
                                      <b>Event Description:
                                      </b>
                                    </Col>
                                  </Row>
                                  {object.eventType === 'Obstruction Tamper'&&(
                                    <>
                                      <label>
                                        An Obstruction Tamper event indicates interference with the TRAC Watch's operation. The TRAC Watch relies
                                        on various sensors to ensure proper function. When a sensor detects an anomaly indicating something placed
                                        between the TRAC Watch and the Participant’s skin such as:
                                      </label>
                                      
                                      <ul  style={{
                                      margin: "18px 0px",
                                      }}>
                                        <li><b>Foreign objects: </b> Items like paper, fabric, or other materials.</li>
                                        <li><b>Substances: </b> Liquids, gels, or powders.</li>
                                      </ul>

                                      <label>
                                        These actions disrupt the sensor readings and trigger an Obstruction Tamper event, compromising the TRAC
                                        Watch's ability to monitor and report data. The Participant has signed an Agreement stating they will not tamper
                                        with the TRAC Watch. The occurrence of an Obstruction Tamper event indicates a disregard for program rules
                                        and is a violation of the Agreement and a violation of the Court Order.
                                      </label>

                                    </>
                                  )}
                                  {object.eventType === 'Strap Tamper'&&(
                                    <>
                                      <label>
                                        A Strap Tamper event indicates interference with the TRAC Watch's operation. The TRAC Watch relies on
                                        various sensors to ensure proper function. When a sensor detects an anomaly indicating the strap's integrity is
                                        compromised, a Strap Tamper event is triggered. This could be due to actions like:
                                      </label>
                                      
                                      <ul   style={{
                                      margin: "18px 0px",
                                      }}>
                                        <li><b>Excessive force: </b> Pulling or yanking the strap with extreme force.</li>
                                        <li><b>Damage: </b> Cutting or attempting to sever the strap.</li>
                                        <li><b>Buckle manipulation: </b> Losing or detaching the buckle.</li>
                                        <li><b>Strap removal: </b>  Completely removing the strap from the watch.</li>
                                      </ul>

                                      <label>
                                        These actions disrupt the sensor readings and trigger a Strap Tamper event, compromising the TRAC Watch's
                                        ability to monitor and report data. The Participant has signed an Agreement stating they will not tamper with the
                                        TRAC Watch. The occurrence of a Strap Tamper event indicates a disregard for program rules and is a violation
                                        of the Agreement and a violation of the Court Order.
                                      </label>
                                      

                                    </>
                                  )}
                                  {object.eventType === 'Case Tamper'&&(
                                    <>
                                      
                                      <label>
                                        A Case Tamper event indicates interference with the TRAC Watch's operation. The TRAC Watch relies on
                                        various sensors to ensure proper function. When a sensor detects an anomaly indicating the case's integrity is
                                        compromised, a Case Tamper event is triggered. This can happen if the TRAC Watch is:

                                      </label>
                                      
                                      <ul   style={{
                                      margin: "18px 0px",
                                      }}>
                                        <li><b>Opened: </b> Any attempt to pry open or disassemble the watch casing.</li>
                                        <li><b>Destroyed: </b> Actions that damage or break the watch casing.</li>
                                        <li><b>Submerged: </b> Exposing the watch to water or other liquids beyond its specified water resistance.</li>
                                      </ul>

                                      <label>
                                        These actions disrupt the sensor readings and trigger a Case Tamper event, compromising the TRAC Watch's
                                        ability to monitor and report data. The Participant has signed an Agreement stating they will not tamper with the
                                        TRAC Watch. The occurrence of a Case Tamper event indicates a disregard for program rules and is a violation
                                        of the Agreement and a violation of the Court Order.

                                      </label>
                                      
                                    </>
                                  )}
                                  {(object.eventType === 'Critically Low Battery' ||
                                    object.eventType === 'Critically Low Watch Battery'
                                  )&&(
                                    <>
                                      <label>
                                        A Critically Low Watch Battery event indicates a significant lapse in maintaining the TRAC Watch. Participants
                                        are required to charge the device at least every 30 days and acknowledge this responsibility through a signed
                                        agreement. This ensures the TRAC Watch, which has a 90-day battery life, remains operational.
                                        <br />
                                        Despite this requirement, and to further prevent this situation, Participants are provided with multiple warnings:
                                      </label>
                                      
                                      <ul   style={{
                                      margin: "18px 0px",
                                      }}>
                                        <li><b>Continuous Battery Monitoring: </b> The TRAC Monitor app displays the watch's battery percentage at all
                                        times, allowing for ongoing awareness of the charge level.</li>
                                        <li><b>Persistent Low Battery Alerts: </b> When the battery reaches 10%, the watch vibrates for one second at
                                        intervals, serving as a constant reminder to charge the device.</li>
                                      </ul>

                                      <label>
                                        The occurrence of a Critically Low Watch Battery event indicates a disregard for program rules and is a violation
                                        of the agreement and the Court Order.
                                      </label>
                                    </>
                                  )}
                                </>
                              )}
                          </p>
                        </div>
                        </div>
                      {/* <div style={{height:"340px",width:"100%",clear:"both"}}></div> */}
                      <img width="100%" src={footerPng} />
                    </>
                  );
                }
                
              })}
              
            </>
          ) : (
            <></>
          )}
        </div>
     
      <>
        {showError ? (
          <>
            <ExceptionHandle />
          </>
        ) : (
          <></>
        )}
      </>
    </>
  );
};

export default Events;

// date for format : 2024-03-17T12:50-0600 -> moment().format("YYYY-MM-DDTHH:mm")+moment().format("Z").replaceAll(":","").replaceAll("+","-")
