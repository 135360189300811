const endpoint = {
  CASELOAD: "/caseload",
  USER: "/user",
  AGENCIES: "/availableAgencies",
  AGENCY_ALERT_LIST: "/availableNotificationLists",
  WEBSITE_USER_TYPES: "/availableWebsiteUserTypes",
  ENROLL_PARTICIPANT: "/participant",
  AVAILABLE_CONTACTS: "/availableContacts",
  AVAILABLE_DEVICES: "/availableDevices",
  ASSIGN_DEVICE: "/assignedDevice",
  AVAILABLE_EVENT_TYPES: "/availableEventTypes",
  AVAILABLE_PROGRAM_TYPES: "/availableProgramTypes",
  SUBSCRIPTIONS: "/subscriptions",
  COMPLIANCE_EVENT: "/complianceEvent",
  COMPLIANCE_EVENTS: "/complianceEvents",
  AVAILABLE_TEST_SCHEDULES: "/availableTestSchedules",
  AVAILABLE_ZONES: "/participant",
  TEST_SCHEDULE: "/testSchedule",
  ADD_WEBSITE_USER: "/user",
  ADMIN_AGENCY: "/admin/agency",
  AMIN_WEBSITE_USER_TYPES: "/admin/availableWebsiteUserTypes",
  AVAILABLE_STATES: "/availableStates",
  AVAILABLE_TIMEZONE: "/availableTimeZones",
  ACTIVE_DEVICES: "/activeDeviceTypes",
  AVAILABLE_DEVICES_AD: "/admin/availableDeviceTypes",
  OPEN_EVENTS: "/openEvents",
  Maintenance_Events: "/openMaintenanceEvents",
  ADMIN_UNVALIDATED_PHOTOS: "/admin/unvalidatedPhotos/next",
  ADMIN_UNVALIDATED_PHOTOS_COUNT: "/admin/unvalidatedPhotos/count",
  TEST_SCORE_NOW: '/testResult',
  VALIDATE_TEST_RESULT: '/admin/validateTestResult'
};

export default endpoint;
